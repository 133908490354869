import React from 'react'
import { RichText } from 'prismic-reactjs'

import { getAnimation } from "../../utils"

import './styles.css'

const Values = ({ values = [] }) => values.map((value, index) => (
  <li key={`value-${index}`} {...getAnimation(0)}>
    <div className="about__values__number">
      <span>{index + 1} — </span>
    </div>
    <div className="about__values__content">
      <h3>{value?.value_title?.text}</h3>
      <RichText render={value?.value_description?.raw} />
    </div>
  </li>
))

const AboutContent = ({ children, data }) => (
  <div className="about content">
    <section className="about__section">
      <h2 {...getAnimation(0)}>{data?.titulo?.text}</h2>
      <div {...getAnimation(200)}>
        <RichText render={data?.story?.raw} />
      </div>
      <div className="about__image">
        <div className="about__image__wrapper" {...getAnimation(0)}>
          <img src={data?.picture?.url} alt={data?.caption?.text} />
          <p>{data?.caption?.text}</p>
        </div>
      </div>
    </section>
    <section className="about__section">
      <h2 {...getAnimation(0)}>{data?.values_title?.text}</h2>
      <ul className="about__values">
        <Values values={data?.values_list} />
      </ul>
    </section>
  </div>
)

export default AboutContent