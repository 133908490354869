import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Contact from "../components/Contact"
import AboutContent from "../components/AboutContent"

import '../styles/index.css'

const Nosotros = ({ data, location }) => (
  <Layout pathname={location?.pathname}>
    <SEO title="Nosotros" />
    <div className="sidebar sidebar--left sidebar--only-desktop">
      <Contact key="nosotros-contact" image="nosotros-large.png" data={data?.allPrismicDireccion?.nodes?.[0]?.data} />
    </div>
    <AboutContent data={data?.allPrismicNosotros?.nodes?.[0]?.data} />
    <div className="sidebar sidebar--right sidebar--only-desktop">
      &nbsp;
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allPrismicDireccion {
      nodes {
        data {
          address {
            html
            text
            raw
          }
          title {
            html
            text
            raw
          }
        }
      }
    }
    allPrismicNosotros {
      nodes {
        data {
          caption {
            html
            text
            raw
          }
          picture {
            alt
            copyright
            url
            thumbnails
          }
          story {
            html
            text
            raw
          }
          titulo {
            html
            text
            raw
          }
          values_title {
            html
            text
            raw
          }
          values_list {
            value_description {
              html
              text
              raw
            }
            value_title {
              html
              text
              raw
            }
          }
        }
      }
    }
  }
`

export default Nosotros
